import {
  Box,
  Center,
  CloseIcon,
  Heading,
  HStack,
  Icon,
  Input,
  InputField,
  Pressable,
  ScrollView,
  Text,
  Toast,
  ToastTitle,
  useToast,
  View,
  VStack,
} from '@gluestack-ui/themed';
import { useMutation } from '@tanstack/react-query';
import type { FC } from 'react';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { PrimaryButton } from '../components/buttons/PrimaryButton';
import { SecundaryButton } from '../components/buttons/SecundaryButton';
import { Carousel } from '../components/camera/Carousel';
import { Title } from '../components/Titles/Title';
import { TranslationContext } from '../contexts/translations/Translation';
import { UserContext } from '../contexts/User';
import { AnalysisDTO, AnalysisService, BasicMedium, ColonyColor, HemolyticProperty } from '../generated/client-api-client';
import type { IAnalysisFormPageProps } from '../routes/Routes';
import { Routes } from '../routes/Routes';
import { styles } from '../theme/styles';
import { uploadToS3 } from '../utils/AwsS3Utils';
import { isString } from '../utils/StringUtils';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormItem } from '../components/form/FormItem';
import { FormItemDropDown } from '../components/form/FormDropDown';
import { FormDatePicker } from '../components/form/FormDatePicker';
import { FormTemperature } from '../components/form/FormTemperature';

export const EditAnalysisFormPage: FC<IAnalysisFormPageProps> = (props: IAnalysisFormPageProps) => {
  const { navigation } = props;
  const { analysisList } = props.route.params;
  const [currentAnalysisIndex, setCurrentAnalysisIndex] = useState<number>(0);
  const [formList, setFormList] = useState<Partial<AnalysisDTO>[]>(analysisList ?? []);
  const { i18n } = useContext(TranslationContext);
  const user = useContext(UserContext);

  const { close, show } = useToast();

  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    sampleId: Yup.string()
      .required(i18n.t('form.required', {
      }))
      .min(1, i18n.t('form.minString', {
        value: 1,
      }))
      .max(9, i18n.t('form.maxString', {
        value: 9,
      })),
    receptionDate: Yup.string().required(i18n.t('form.required')).test('is-valid-date', i18n.t('form.dateBeforeThanToday'), (value: string) => new Date(value) < new Date()),
    sampleTemperature: Yup.string().required(i18n.t('form.required')).test(
      'is-valid-temperature',
      i18n.t('form.temperatureFormat'),
      (value) => {
        if (!value) return false; // Reject empty values
        const floatRegex = /^[+-]?\d+(\.\d+)?$/; // Regex for valid float
        return floatRegex.test(value);
      }
    ),
    properties: Yup.object().shape({
      color: Yup.string().required(i18n.t('form.required')),
      basicMedium: Yup.string().required(i18n.t('form.required')),
      hemolyticProperty: Yup.string().required(i18n.t('form.required')),
    }),
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const mappedValues = {
        ...values,
        receptionDate: values.receptionDate,
        sampleTemperature: Number(values.sampleTemperature),
      };
      const id = await saveAnalysis(mappedValues);
      const updatedFormList = formList.map((analysis, index) => {
        if (index === currentAnalysisIndex) {
          return { ...mappedValues, id };
        }
        return analysis;
      });
      if (currentAnalysisIndex === formList.length - 1) {
        if (formList.length === 1) {
          return navigation.navigate(Routes.ANALYSIS, { analysisId: id });
        }
        return navigation.navigate(Routes.ANALYSIS_LIST, { watchAnalysisIds: updatedFormList.map((analysis) => analysis.id!) });
      }

      const nextIndex = currentAnalysisIndex + (1 % formList.length);
      setFormList(updatedFormList);
      setCurrentAnalysisIndex(nextIndex);
      return Promise.resolve();
    },
    initialValues: analysisList[0],
  });

  const showStartAnalysisJobErrorToast = () => {
    show({
      id: 'startAnalysisJobError',
      placement: 'bottom left',
      duration: 10_000,
      render: ({ id }) => (
        <Toast action="error" variant="accent" alignItems="center" gap={10}>
          <ToastTitle>{i18n?.t('page.analysis-details.toast.start-analysis-job-error')}</ToastTitle>
          <Pressable onPress={() => close(id)}>
            <Icon as={CloseIcon} />
          </Pressable>
        </Toast>
      ),
    });
  };

  const { mutateAsync: saveAnalysis, isPending: isSavingAnalysis } = useMutation({
    mutationFn: async (data: AnalysisDTO) => {
      if (!data.id) {
        const response = await AnalysisService.analysisControllerCreate(user!.currentWorkspace!.id!, {
          name: data?.name ?? '',
          sampleId: data?.sampleId ?? '',
          receptionDate: new Date(data?.receptionDate ?? new Date()).toISOString(),
          properties: data.properties ?? {},
          sampleTemperature: data.sampleTemperature,
        });

        await uploadToS3(data.imageUrl!, response.uploadURL);
        try {
          await AnalysisService.analysisControllerStartAnalysis(user!.currentWorkspace!.id!, response.id!);
        } catch (error) {
          showStartAnalysisJobErrorToast();
        }
        return response.id;
      }
      if (
        data.name === formList[currentAnalysisIndex].name &&
        JSON.stringify(data.properties) === JSON.stringify(formList[currentAnalysisIndex].properties) &&
        data.receptionDate === formList[currentAnalysisIndex].receptionDate &&
        data.imageUrl === formList[currentAnalysisIndex].imageUrl &&
        data.sampleId === formList[currentAnalysisIndex].sampleId
      ) {
        return data.id;
      }
      await AnalysisService.analysisControllerUpdate(user!.currentWorkspace!.id!, data.id ?? '', data);
      return data.id;
    },
  });

  useEffect(() => {
    if(currentAnalysisIndex) {
      formik.setValues(formList[currentAnalysisIndex]);
    }
  }, [currentAnalysisIndex]);

  return (
    <View style={styles.layout}>
      <VStack>
        <HStack justifyContent="space-between">
          <Title title={i18n?.t('page.analysis-details.title')} />
          <Center>
            <Box style={{ backgroundColor: 'white', borderRadius: 10, padding: 10, shadowColor: '#171717', shadowOffset: { width: -2, height: 4 }, shadowOpacity: 0.2, shadowRadius: 3 }}>
              <Text>
                {new Date(formik.values.createdAt!).toLocaleDateString(i18n.locale)} | {new Date(formik.values.createdAt!).toLocaleTimeString(i18n.locale)}
              </Text>
            </Box>
          </Center>
        </HStack>
        <HStack style={{ marginVertical: 10, gap: 30, marginTop: 50 }}>
          <Box
            style={{
              aspectRatio: '1/1',
              borderRadius: 10,
              maxHeight: 450,
              maxWidth: 450,
              minHeight: 150,
              minWidth: 150,
              height: '100%',
              width: '100%',
              flexGrow: 1,
            }}
          >
            <Carousel
              disableControls={true}
              overrideCurrentPhotoIndex={currentAnalysisIndex}
              onPhotoChange={setCurrentAnalysisIndex}
              photoUriList={analysisList.map((analysis) => analysis.imageUrl!)}
            />
          </Box>

          <VStack
            style={{
              backgroundColor: 'white',
              borderRadius: 10,
              flex: 1.5,
              marginLeft: 50,
              paddingHorizontal: 50,
              paddingVertical: 20,
              shadowColor: '#171717',
              shadowOffset: { width: -2, height: 4 },
              shadowOpacity: 0.2,
              shadowRadius: 3,
              maxHeight: 450,
            }}
          >
            <ScrollView showsVerticalScrollIndicator={true} overflow='scroll'>
              <VStack>
                <Heading size="md">{i18n?.t('page.new-analysis.form.analysis-information-section-label')}</Heading>
                <FormItem
                  form={formik}
                  propertyName="id"
                  label={i18n?.t('page.new-analysis.form.analysis-id-label')} isDisabled={true}>
                    <Input>
                      <InputField value={formik.values.id} onChangeText={formik.handleChange(`id`)} onBlur={formik.handleBlur(`id`)}/>
                    </Input>
                </FormItem>
              </VStack>

              <VStack>
                <Heading size="md">{i18n?.t('page.new-analysis.form.sample-information-section-label')}</Heading>
                <FormItem
                  isRequired
                  form={formik}
                  propertyName="sampleId"
                  label={i18n?.t('page.new-analysis.form.sample-id-label')} isDisabled={!!formik.values?.id}>
                    <Input>
                      <InputField maxLength={9} value={formik.values.sampleId ?? ''} onChangeText={formik.handleChange(`sampleId`)} onBlur={formik.handleBlur(`sampleId`)}/>
                    </Input>
                  </FormItem>

                  <FormItem
                    isRequired
                    form={formik}
                    label={i18n?.t('page.new-analysis.form.sample-received-date-label')}
                    propertyName="receptionDate" isDisabled={!!formik.values?.id}>
                      <FormDatePicker value={formik.values.receptionDate ? new Date(formik.values.receptionDate) : undefined} onChange={(date) => formik.setFieldValue(`receptionDate`, date?.toISOString())} filterDate={(date: Date) => new Date() > date}/>
                  </FormItem>
                  <FormItem
                    isRequired
                    form={formik}
                    label={i18n?.t('page.new-analysis.form.sample-temperature-label')}
                    propertyName="sampleTemperature" isDisabled={!!formik.values?.id}>
                      <FormTemperature value={formik.values.sampleTemperature ? String(formik.values.sampleTemperature) : ''} onChange={formik.handleChange("sampleTemperature")}/>
                  </FormItem>

                  <FormItem
                    isDisabled
                    form={formik}
                    label={i18n?.t('page.new-analysis.form.method-reference')} propertyName="properties.methodReference">
                      <FormItemDropDown 
                        onChange={formik.handleChange(`properties.methodReference`)}
                        placeholder={i18n?.t('page.new-analysis.form.method-reference-placeholder')}
                        options={[
                          {value: '1', label: i18n?.t('page.new-analysis.form.method-reference')},
                        ]}
                        value={'1'} />
                  </FormItem>

              </VStack>
              <VStack>
                <Heading size="md">{i18n?.t(`page.new-analysis.form.pre-filter-section-label`)}</Heading>
                <FormItem
                  isRequired
                  form={formik}
                  label={i18n?.t('page.new-analysis.form.filter.color.label')} propertyName="properties.color">
                    <FormItemDropDown 
                      onChange={formik.handleChange(`properties.color`)}
                      options={Object.values(ColonyColor)
                        .filter(isString)
                        .map((color) => ({ value: color as string, label: i18n?.t(`page.new-analysis.form.filter.color.${color}`) }))}
                      placeholder={i18n?.t(`page.new-analysis.form.filter.color.select`)}
                      value={formik.values.properties?.color} />
                </FormItem>

                <FormItem
                  isRequired
                  form={formik}
                  label={i18n?.t('page.new-analysis.form.filter.basicMedium.label')} propertyName="properties.basicMedium">
                    <FormItemDropDown 
                      onChange={formik.handleChange(`properties.basicMedium`)}
                      options={Object.values(BasicMedium)
                        .filter(isString)
                        .map((basicMedium) => ({ value: basicMedium as string, label: i18n?.t(`page.new-analysis.form.filter.basicMedium.${basicMedium}`) }))}
                      placeholder={i18n?.t(`page.new-analysis.form.filter.basicMedium.select`)}
                      value={formik.values.properties?.basicMedium} />
                </FormItem>

                <FormItem
                  isRequired
                  form={formik}
                  label={i18n?.t('page.new-analysis.form.filter.hemolyticProperty.label')} propertyName="properties.hemolyticProperty">
                    <FormItemDropDown 
                      onChange={formik.handleChange(`properties.hemolyticProperty`)}
                      options={Object.values(HemolyticProperty)
                        .filter(isString)
                        .map((hemolyticProperty) => ({ value: hemolyticProperty as string, label: i18n?.t(`page.new-analysis.form.filter.hemolyticProperty.${hemolyticProperty}`) }))}
                      placeholder={i18n?.t(`page.new-analysis.form.filter.hemolyticProperty.select`)}
                      value={formik.values.properties?.hemolyticProperty} />
                </FormItem>

              </VStack>
            </ScrollView>
            <div id="calendar-portal"></div>
            <HStack justifyContent="space-evenly" flexWrap="wrap">
              <SecundaryButton
                content={i18n?.t('common.cancel')}
                style={{ margin: 10 }}
                onPress={async () => {
                  navigation.goBack();
                }}
              />
              <SecundaryButton
                content={i18n.t('page.new-analysis.form.previous-button-label')}
                style={{ margin: 10 }}
                isDisable={currentAnalysisIndex === 0}
                onPress={async () => {
                  const updatedFormList = formList.map((analysis, index) => {
                    if (index === currentAnalysisIndex) {
                      return { ...formik.values };
                    }
                    return analysis;
                  });
                  const previousIndex = (currentAnalysisIndex - 1 + formList.length) % formList.length;
                  setFormList(updatedFormList);
                  setCurrentAnalysisIndex(previousIndex);
                }}
              />
              <PrimaryButton
                content={currentAnalysisIndex === formList.length - 1 ? i18n.t('page.new-analysis.form.save-form-button-label') : i18n.t('page.new-analysis.form.next-button-label')}
                onPress={async () => {
                  formik.handleSubmit();
                }}
                style={{ margin: 10 }}
                isLoading={isSavingAnalysis}
              />
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </View>
  );
};
